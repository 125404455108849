<template>
  <div class="home">
    <div class="banner">
      <img src="@/assets/img/1.png" alt="" />
    </div>
    <section class="section1" v-for="(item, index) in data" :key="index">
      <img :src="item.img" alt="" />
      <div class="img2_box" :class="`sectionBox${index + 1}`" v-if="item.img2">
        <a style="display: inline-block" :href="item.path" target="_blank">
          <img :src="item.img2" alt="" />
          <i></i>
          <b></b>
        </a>
      </div>
    </section>
    <footer>
      <a href="https://beian.miit.gov.cn">粤ICP备20072872号</a>
      <span>深圳点筹农业供应链有限公司版权所有</span>
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: [
        {
          img: require("@/assets/img/2.png"),
        },
        {
          img: require("@/assets/img/3.png"),
        },
        {
          img: require("@/assets/img/4.png"),
        },
        {
          img: require("@/assets/img/5.png"),
          img2: require("@/assets/click/1.png"),
          path: "https://720yun.com/t/d6vktmfw01l#scene_id=76482471",
        },
        {
          img: require("@/assets/img/6.png"),
          img2: require("@/assets/click/2.png"),
          path: "https://720yun.com/t/d6vktmfw01l#scene_id=76863838",
        },
        {
          img: require("@/assets/img/7.png"),
          img2: require("@/assets/click/3.png"),
          path: "https://720yun.com/t/d6vktmfw01l#scene_id=82249555",
        },
        {
          img: require("@/assets/img/8.png"),
        },
        {
          img: require("@/assets/img/9.png"),
        },
        {
          img: require("@/assets/img/10.png"),
          img2: require("@/assets/click/4.png"),
          path: "https://720yun.com/t/d6vktmfw01l#scene_id=76315677",
        },
        {
          img: require("@/assets/img/11.png"),
          img2: require("@/assets/click/5.png"),
          path: "https://720yun.com/t/d6vktmfw01l#scene_id=76315681",
        },
        {
          img: require("@/assets/img/12.png"),
          img2: require("@/assets/click/6.png"),
          path: "https://720yun.com/t/d6vktmfw01l#scene_id=76338791",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.banner {
  position: relative;
  img {
    width: 100%;
  }
}
.section1 {
  position: relative;
  img {
    width: 100%;
  }
  .img2_box {
    width: 1188px;
    height: 596px;
    position: absolute;
    top: 17%;
    left: 18.8%;
    overflow: hidden;
    cursor: pointer;
    img {
      width: 100%;
    }
    &:hover i {
      opacity: 0.5;
    }
    &:hover b {
      top: 50%;
      opacity: 1;
    }
    i {
      width: 100%;
      height: 100%;
      background: #000;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      transition: all 0.8s;
    }
    b {
      width: 80px;
      height: 71px;
      background: url(//s.expoon.com/image/expo/qjpincha/degree720.png)
        no-repeat center;
      position: absolute;
      left: 50%;
      top: 0;
      margin-left: -40px;
      margin-top: -35px;
      opacity: 0;
      transition: all 0.8s;
    }
  }
}
.section1 .sectionBox5 {
  top: 13%;
}
.section1 .sectionBox6 {
  top: 10%;
}
.section1 .sectionBox10 {
  top: 13%;
}
.section1 .sectionBox11 {
  top: 10%;
}
</style>

<style lang="less" scoped>
@media (max-width: 1880px) {
  .home .section1 .img2_box {
    width: 1167px;
    height: 583px;
  }
}
@media (max-width: 1770px) {
  .home .section1 .img2_box {
    width: 1100px;
    height: 548px;
  }
}
@media (max-width: 1660px) {
  .home .section1 .img2_box {
    width: 1030px;
    height: 514px;
  }
}
@media (max-width: 1550px) {
  .home .section1 .img2_box {
    width: 962px;
    height: 481px;
  }
}
@media (max-width: 1440px) {
  .home .section1 .img2_box {
    width: 886px;
    height: 442px;
  }
}

@media (max-width: 1360px) {
  .home .section1 .img2_box {
    width: 846px;
    height: 422px;
  }
}
@media (max-width: 1280px) {
  .home .section1 .img2_box {
    width: 795px;
  }
}

@media (max-width: 750px) {
  .home .section1 .img2_box {
    width: 62%;
    height: auto;
  }
  .section1 .img2_box b {
    width: 50px;
    height: 50px;
    margin-left: -30px;
    margin-top: -26px;
    background-size: contain;
  }
  footer {
    font-size: 14px;
    padding: 15px 0;
    a {
    }
    span {
      color: #fff;
      margin-left: 12px;
      margin-top: 10px;
    }
  }
}
footer {
  background-color: #1d1d1d;
  padding: 20px 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  a {
    color: #6e99ff;
    text-decoration: none;
  }
  span {
    color: #fff;
    margin-left: 12px;
  }
}
</style>
